@use '@material/layout-grid/mixins' as layout-grid;

.pm-welcome-grid {
  margin-top: 2rem;
}

.pm-courses-grid {
  margin-top: 2rem;
}

.pm-welcome-grid + .pm-courses-grid {
  margin-top: 1.5rem;
}

.pm-auth-grid {
  display: grid;
  min-height: 100%;

  &__progress {
    border-radius: 4px 4px 0 0;

    @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
      border-radius: 0;
    }
  }

  @media screen and (min-width: #{layout-grid.breakpoint-min(tablet)}) {
    align-content: center;
    justify-content: center;
    padding: 1.5rem;
  }
}

.pm-course-grid {
  margin-bottom: calc(56px + 2rem);
  padding: 1rem;

  &__progress {
    max-width: 320px;
  }
}

.pm-section-grid {
  margin-top: 1.5rem;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__tag {
    margin-left: 0.5em;
    padding: 0.1em 0.3em;
    font-size: 0.8rem;
    line-height: 1;
    text-transform: uppercase;
  }
}

.pm-lessons-grid {
  display: grid;
  gap: 0.75rem;

  @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
    gap: 0.25rem;
  }
}
