$pm-primary: hsl(169, 100%, 37%);
$pm-on-primary: hsl(0, 0%, 100%);

$pm-primary--light: hsl(168, 62%, 93%);
$pm-on-primary--light: hsl(0, 0%, 100%);

$pm-secondary: hsl(3, 75%, 65%);
$pm-on-secondary: hsl(0, 0%, 100%);

$pm-secondary--light: hsl(4, 77%, 96%);
$pm-on-secondary--light: hsl(0, 0%, 100%);

$pm-disabled: hsl(180, 5%, 76%);
$pm-on-disabled: hsl(0, 0%, 100%);

$pm-disabled--light: hsl(180, 3%, 89%);
$pm-on-disabled--light: hsl(0, 0%, 0%);

$pm-disabled--dark: hsla(165, 1%, 58%);
$pm-on-disabled--dark: hsl(0, 0%, 100%);

$pm-background: hsl(0, 0%, 96%);
$pm-on-background: hsl(0, 0%, 0%);

$pm-surface: hsl(0, 0%, 100%);
$pm-on-surface: hsl(0, 0%, 0%);

$pm-error: $pm-secondary;
$pm-on-error: $pm-on-secondary;

$pm-success: $pm-primary;
$pm-on-success: $pm-on-primary;

// custom theme

$pm-gradient--green: linear-gradient(225deg, hsl(170, 100%, 38%) 0%, hsl(144, 55%, 70%) 100%);
$pm-gradient--orange: linear-gradient(225deg, hsl(41, 95%, 68%) 0%, hsl(30, 100%, 70%) 48%, hsl(358, 100%, 81%) 100%);

$pm-gradient--green--reversed: linear-gradient(107deg, hsl(170, 100%, 38%) 0%, hsl(144, 55%, 70%) 100%);
$pm-gradient--orange--reversed: linear-gradient(
  107deg,
  hsl(41, 95%, 68%) 0%,
  hsl(30, 100%, 70%) 48%,
  hsl(358, 100%, 81%) 100%
);
