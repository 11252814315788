.pm-form {
  .pm-text-field {
    width: 100%;
  }

  &__title + &__form {
    margin-top: 1em;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1em;

    &--right {
      justify-content: flex-end;
    }
  }
}

.pm-errors {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;

  &__error {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    &__icon {
      margin-right: 0.5em;
      font-size: 0.875rem;
    }
  }
}
