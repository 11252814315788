@use '@material/top-app-bar/mixins';
@use '@material/theme/mixins' as theme;
@use '../base/variables';

.mdc-top-app-bar--fixed-adjust {
  transition: padding-top 200ms linear;
}

.pm-top-app-bar {
  @include mixins.ink-color(variables.$pm-disabled);
  @include mixins.icon-ink-color(variables.$pm-disabled);
  @include mixins.fill-color(variables.$pm-surface);

  top: 0;
  transition: box-shadow 200ms linear, top 200ms linear;

  &__section--align-end {
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;
  }

  &__logo {
    max-width: 124px;
  }

  &--hidden {
    top: -84px;
  }

  &--hidden--adjust {
    padding-top: 0;
  }

  &__layout-grid {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.mdc-top-app-bar__section {
  padding-right: 0;
  padding-left: 0;
}
