@use '../base/variables' as pm-theme;
@use '@material/layout-grid/mixins' as layout-grid;
@use '@material/typography/mixins' as typography-mixins;
@use '@material/theme/mixins' as mdc-theme-mixins;

.pm-card {
  &__content {
    padding: 0.5em;
  }

  &--auth {
    width: 100%;
    max-width: 600px;
    height: 100%;

    .pm-card__content {
      @media screen and (min-width: #{layout-grid.breakpoint-min(tablet)}) {
        padding: 2em;
      }
    }

    @media screen and (min-width: 420px) {
      width: auto;
      min-width: calc(420px - 3rem);
      height: auto;
    }

    @media screen and (min-width: 600px) {
      width: auto;
      min-width: calc(600px - 3rem);
      height: auto;
    }
  }

  &--course {
    height: 100%;

    .pm-card__content {
      padding: 1em;
    }

    &__primary-action {
      height: 100%;
    }

    &__media {
      background-color: pm-theme.$pm-disabled;

      &__premium {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
      }
    }

    &__progress {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__count {
        margin-left: 0.5em;
        white-space: nowrap;
      }
    }

    &__name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--fake {
      opacity: 0.4;

      .pm-card--course__name {
        text-overflow: clip;
      }
    }

    &--wip {
      background: pm-theme.$pm-gradient--orange--reversed;

      .pm-card__content {
        display: grid;
        align-content: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 1.5em;
        text-align: center;
        justify-items: center;
        gap: 0.5em;
      }
    }

    &__title {
      height: 3em;
      line-height: 1.5em;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &--welcome {
    background: pm-theme.$pm-gradient--green--reversed;

    .pm-card__content {
      display: grid;
      align-items: center;
      justify-content: space-between;
      padding: 22px;
      gap: 1rem;

      @media screen and (min-width: #{layout-grid.breakpoint-min(tablet)}) {
        grid-template-columns: auto auto;
      }
    }

    &__background {
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
    }

    &__intro {
      display: grid;
      align-items: center;
      gap: 2rem;

      &__text {
        display: grid;
        gap: 0.8rem;
      }

      @media screen and (min-width: #{layout-grid.breakpoint-min(tablet)}) {
        grid-template-columns: auto auto;
      }
    }
  }

  &--lesson {
    flex-direction: row;
    cursor: pointer;

    &:hover {
      @include mdc-theme-mixins.prop(background-color, #F0F0F0);
    }

    .mdc-card__media--square {
      flex-shrink: 0;
      width: 80px;
      background: pm-theme.$pm-disabled;

      &--active {
        background:
          linear-gradient(
            var(--rotation, 107deg),
            hsl(170, 100%, 38%) 0%,
            hsl(144, 55%, 70%) 100%
          );
      }

      &--locked {
        background:
          linear-gradient(
            var(--rotation, 107deg),
            hsl(40, 96%, 69%) 0%,
            hsl(1, 100%, 81%) 100%
          );
      }

      @media screen and (max-width: 700px) {
        width: 60px;
      }

      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        width: 50px;
      }
    }

    .mdc-card__media-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__index {
      @include typography-mixins.typography(headline2);

      margin: 0;

      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        @include typography-mixins.typography(headline3);
      }
    }

    &__name {
      @include typography-mixins.typography(headline3);
      @include typography-mixins.overflow-ellipsis;
      @include mdc-theme-mixins.prop(color, text-primary-on-light);

      margin: 0 0.5em 0 0.5em;

      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        @include typography-mixins.typography(button);
      }
    }

    &--fake {
      .pm-card--lesson__name {
        text-overflow: clip;
      }
    }

    &__start {
      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        padding: 0;
      }
    }

    &__inner {
      display: grid;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 30px;
      grid-template-columns: auto auto;

      @media screen and (max-width: 700px) {
        padding: 0 8px;
      }

      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        padding: 0 5.5px;
      }
    }

    &__name--locked {
      opacity: 50%;
    }

    &__actions {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      gap: 20px;

      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        gap: 0.5em;
      }
    }

    &__levels {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1em;

      @media screen and (max-width: 700px) {
        gap: 0.5em;
      }

      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        gap: 0.25em;
      }
    }

    &__level {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr;
      gap: 0.5em;
    }

    &__level__name {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }

    &__level__indicator {
      width: 100%;
      height: 10px;
      border-radius: 5px;
      background: pm-theme.$pm-disabled;

      @media screen and (max-width: 700px) {
        width: 30px;
      }

      @media screen and (max-width: 600px) {
        width: 20px;
      }

      @media screen and (max-width: 500px) {
        width: 10px;
      }
    }

    &__level--active {
      .pm-card--lesson__level__indicator {
        background: pm-theme.$pm-primary;
      }
    }
  }
}
