@use '@material/button/mixins';
@use '@material/theme/mixins'as theme;
@use '../base/variables';

.pm-router-link {
  .pm-button {
    @include mixins.ink-color(variables.$pm-on-surface);
    @include mixins.horizontal-padding(16px);

    .pm-button__label {
      border-bottom: 2px solid transparent;
    }
  }

  &--dark {
    .pm-button {
      @include mixins.ink-color(variables.$pm-on-surface);
    }
  }
}

.pm-router-link-exact-active {
  .pm-button {
    @include mixins.ink-color(variables.$pm-on-surface);

    .pm-button__label {
      @include theme.prop('border-color', 'primary');
    }
  }
}
