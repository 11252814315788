@use '@material/animation/functions' as animation;

@keyframes pm-explode {
  from {
    transform: scale(0.3);
    opacity: 0;
  }

  90% {
    transform: scale(1.2);
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pm-sparkle {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }

  10% {
    transform: scale(calc(1 + var(--scale, 0)));
    opacity: 0;
  }

  to {
    transform: scale(0);
    opacity: 0;
  }
}

.pm-explode-animation {
  display: grid;

  &__main {
    position: relative;
    animation: animation.enter(pm-explode, 1s);
    font-size: 25vh;
  }

  &__alternate {
    position: absolute;
    top: var(--top, 0);
    left: var(--left, 0);
    animation: animation.enter(pm-sparkle, 20s) infinite;
    font-size: 5vh;
    animation-fill-mode: both;
  }
}
