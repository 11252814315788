@use '@material/button/mixins';
@use '@material/fab/mixins' as fab-mixins;
@use '@material/theme/mixins'as theme;
@use '@material/ripple/mixins' as ripple;
@use '@material/layout-grid/mixins' as layout-grid;
@use '@material/animation/functions' as animation;
@use '../base/variables' as pm-theme;

.pm-button {
  @include mixins.disabled-container-fill-color(pm-theme.$pm-disabled);
  @include mixins.disabled-ink-color(pm-theme.$pm-on-disabled);
  @include mixins.disabled-outline-color(pm-theme.$pm-disabled);

  border-radius: 0;

  &--gradient {
    @include mixins.container-fill-color(pm-theme.$pm-primary);
    @include mixins.ink-color(pm-theme.$pm-on-primary);

    background: pm-theme.$pm-gradient--green--reversed;

    .mdc-button__ripple {
      @include ripple.states(pm-theme.$pm-on-primary);
    }
  }

  &--orange {
    @include mixins.container-fill-color(pm-theme.$pm-secondary);
    @include mixins.ink-color(pm-theme.$pm-on-secondary);

    background: pm-theme.$pm-gradient--orange--reversed;

    .mdc-button__ripple {
      @include ripple.states(pm-theme.$pm-on-secondary);
    }
  }

  /*  &--orange {
    @include mixins.container-fill-color(pm-theme.$pm-secondary);
    @include mixins.ink-color(pm-theme.$pm-on-secondary);

    background: pm-theme.$pm-gradient--orange--reversed;

    .mdc-button__ripple {
      @include ripple.states(pm-theme.$pm-on-secondary);
    }
  } */

  &--white {
    @include mixins.container-fill-color(pm-theme.$pm-surface);
    @include mixins.ink-color(pm-theme.$pm-primary);

    .mdc-button__ripple {
      @include ripple.states(pm-theme.$pm-primary);
    }
  }

  &--success {
    @include mixins.container-fill-color(pm-theme.$pm-primary);
    @include mixins.ink-color(pm-theme.$pm-on-primary);
    @include mixins.outline-color(pm-theme.$pm-primary);

    .mdc-button__ripple {
      @include ripple.states(pm-theme.$pm-on-primary);
    }
  }

  &--red {
    --mdc-theme-primary: #{pm-theme.$pm-secondary};
  }

  &--error {
    @include mixins.container-fill-color(pm-theme.$pm-secondary);
    @include mixins.ink-color(pm-theme.$pm-on-secondary);
    @include mixins.outline-color(pm-theme.$pm-secondary);

    .mdc-button__ripple {
      @include ripple.states(pm-theme.$pm-on-secondary);
    }
  }

  @media screen and (min-width: #{layout-grid.breakpoint-min(desktop)}) {
    @include mixins.height(48px);
    @include mixins.horizontal-padding(30px);

    &--big {
      @include mixins.height(80px);
    }

    &--small {
      @include mixins.height(36px);
    }
  }
}

.pm-game-button {
  @include mixins.container-fill-color(surface);
  @include mixins.ink-color(on-surface);
  @include mixins.outline-color(disabled);
  @include mixins.disabled-container-fill-color(surface);
  @include mixins.disabled-ink-color(on-surface);
  @include mixins.disabled-outline-color(disabled);

  .mdc-button__ripple {
    @include ripple.states(on-surface);
  }

  &[data-answered='true'] {
    --mdc-theme-disabled: #{pm-theme.$pm-disabled};
    --mdc-theme-surface: #{pm-theme.$pm-surface};
    --mdc-theme-on-surface: #{pm-theme.$pm-disabled};

    &.pm-game-button--error {
      &[data-selected='true'] {
        --mdc-theme-disabled: #{pm-theme.$pm-secondary};
        --mdc-theme-surface: #{pm-theme.$pm-secondary};
        --mdc-theme-on-surface: #{pm-theme.$pm-on-secondary};
      }
    }

    &.pm-game-button--success {
      --mdc-theme-disabled: #{pm-theme.$pm-primary};
      --mdc-theme-on-surface: #{pm-theme.$pm-primary};

      &[data-selected='true'] {
        --mdc-theme-surface: #{pm-theme.$pm-primary};
        --mdc-theme-on-surface: #{pm-theme.$pm-on-primary};
      }
    }
  }
}

.pm-icon-button::before,
.pm-icon-button::after {
  border-radius: 0;
}

.pm-icon-button {
  @include theme.prop('background', 'primary--light');
}

.pm-fab {
  &--back {
    position: fixed;
    bottom: 1rem;
    left: 1rem;

    @media screen and (min-width: #{layout-grid.breakpoint-min(desktop)}) {
      bottom: 2rem;
      left: 2rem;
    }
  }
}

.pm-button--heart {
  background: url('../assets/heart--disabled.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px 24px;
}

.mdc-icon-button {
  &__icon {
    object-fit: contain;
  }
}
