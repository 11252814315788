@use '@material/typography/mixins' as mdc-typography-mixins;
@use '@material/theme/mixins' as mdc-theme-mixins;

.mdc-contact-form {
  max-width: 1280px;

  &__greet {
    @include mdc-typography-mixins.typography(headline1);

    max-width: 40em;
    margin: 1em auto;
    font-weight: 400;
    text-align: center;
  }

  &__text {
    @include mdc-typography-mixins.typography(headline5);

    max-width: 40em;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  &__hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mdc-text-field--textarea {
    width: 100%;
  }

  .mdc-button {
    --mdc-theme-primary: hsl(191, 35%, 41%);
  }
}
