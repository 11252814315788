@use 'sass:string';
@use './base/variables' as pm-theme;
@use '@material/theme/variables' as theme-variables with (
  $primary: pm-theme.$pm-primary,
  $on-primary: pm-theme.$pm-on-primary,

  $accent: pm-theme.$pm-secondary,
  $secondary: pm-theme.$pm-secondary,
  $on-secondary: pm-theme.$pm-on-secondary,

  $background: pm-theme.$pm-background,

  $surface: pm-theme.$pm-surface,
  $on-surface: pm-theme.$pm-on-surface,

  $error: pm-theme.$pm-error,
  $on-error: pm-theme.$pm-on-error,

  $property-values: (
    primary: pm-theme.$pm-primary,
    'primary--light': pm-theme.$pm-primary--light,
    secondary: pm-theme.$pm-secondary,
    'secondary--light': pm-theme.$pm-secondary--light,
    'disabled': pm-theme.$pm-disabled,
    'disabled--light': pm-theme.$pm-disabled--light,
    'disabled--dark': pm-theme.$pm-disabled--dark,
    background: pm-theme.$pm-background,
    surface: pm-theme.$pm-surface,
    error: pm-theme.$pm-error,
    success: pm-theme.$pm-success,
    on-primary: pm-theme.$pm-on-primary,
    'on-primary--light': pm-theme.$pm-on-primary--light,
    on-secondary: pm-theme.$pm-on-secondary,
    'on-secondary--light': pm-theme.$pm-on-secondary--light,
    'on-disabled': pm-theme.$pm-on-disabled,
    'on-disabled--light': pm-theme.$pm-on-disabled--light,
    'on-disabled--dark': pm-theme.$pm-on-disabled--dark,
    on-background: pm-theme.$pm-on-background,
    on-surface: pm-theme.$pm-on-surface,
    on-error: pm-theme.$pm-on-error,
    on-success: pm-theme.$pm-on-success,

    text-primary-on-background: rgba(black, 0.87),
    text-secondary-on-background: rgba(black, 0.54),
    text-hint-on-background: rgba(black, 0.38),
    text-disabled-on-background: rgba(black, 0.38),
    text-icon-on-background: rgba(black, 0.38),

    text-primary-on-light: rgba(black, 0.87),
    text-secondary-on-light: rgba(black, 0.54),
    text-hint-on-light: rgba(black, 0.38),
    text-disabled-on-light: rgba(black, 0.38),
    text-icon-on-light: rgba(black, 0.38),

    text-primary-on-dark: white,
    text-secondary-on-dark: rgba(white, 0.7),
    text-hint-on-dark: rgba(white, 0.5),
    text-disabled-on-dark: rgba(white, 0.5),
    text-icon-on-dark: rgba(white, 0.5),
  ),
);
@use '@material/button/variables' as button-variables with (
  $outline-color: pm-theme.$pm-disabled,
  $disabled-container-color: pm-theme.$pm-disabled,
  $disabled-ink-color: pm-theme.$pm-on-disabled,
);
@use '@material/layout-grid/variables' as layout-grid-variables with (
  $breakpoints: (
    desktop: 1024px,
    tablet: 560px,
    phone: 0
  ),

  $max-width: 1280px,
);
@use '@material/linear-progress/variables' as linear-progress-variables with (
  $baseline-buffer-color: pm-theme.$pm-disabled--light,
);
@use '@material/typography/variables' as typography-variables with (
  $font-family: string.unquote('Source Sans Pro, Roboto, sans-serif'),

  $styles-headline1: (
    font-size: 48px,
    line-height: 60px,
    font-weight: 700,
    letter-spacing: 0,
  ),
  $styles-headline2: (
    font-size: 36px,
    line-height: 44px,
    font-weight: 700,
    letter-spacing: 0,
  ),
  $styles-headline3: (
    font-size: 24px,
    line-height: 31px,
    font-weight: 700,
    letter-spacing: 0,
  ),
  $styles-body1: (
    font-size: 16px,
    line-height: 20px,
    font-weight: 400,
    letter-spacing: 0,
  ),
  $styles-body2: (
    font-size: 14px,
    line-height: 18px,
    font-weight: 400,
    letter-spacing: 0,
  ),
  $styles-button: (
    font-size: 16px,
    line-height: 20px,
    font-weight: 700,
    text-transform: none,
    letter-spacing: 0,
  ),
);
@use '@material/top-app-bar/variables' as top-app-bar-variables with (
  $row-height: 84px,
);
@use 'material-components-web/material-components-web';
@use 'bootstrap/scss/bootstrap-reboot';
@use './base';
@use './components';
@use '@material/layout-grid/mixins' as layout-grid;

.pm-not-mobile {
  @media screen and (max-width: 639px) {
    display: none;
  }
}

.pm-only-mobile {
  @media screen and (min-width: 640px) {
    display: none;
  }
}

@media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
  :root {
    --mdc-typography-headline1-font-size: 36px;
    --mdc-typography-headline1-line-height: 44px;
    --mdc-typography-headline2-font-size: 24px;
    --mdc-typography-headline2-line-height: 31px;
    --mdc-typography-headline3-font-size: 16px;
    --mdc-typography-headline3-line-height: 28px;
    --mdc-typography-body1-font-size: 14px;
    --mdc-typography-body1-line-height: 18px;
    --mdc-typography-button-font-size: 14px;
    --mdc-typography-button-line-height: 18px;
  }
}
