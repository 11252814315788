@use '@material/animation/functions' as animation;

// enter
// exit-pernament
// exit-temporary
// standard

@keyframes pm-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pm-heart-explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.pm-router-enter-active {
  animation: animation.standard(pm-fade, 0.25s);
}

.pm-router-leave-active {
  animation: animation.standard(pm-fade, 0.25s) reverse;
}

.pm-button--transition {
  transition:
    animation.standard(color, 500ms),
    animation.standard(background-color, 500ms),
    animation.standard(border-color, 500ms);
}

.pm-heart-enter-active {
  animation: animation.standard(pm-heart-explode, 0.25s) reverse;
}

.pm-heart-leave-active {
  animation: animation.standard(pm-heart-explode, 0.25s);
}
