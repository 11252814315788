@use '../base/variables' as pm-theme;

.pm-fake {
  line-height: 1;

  &__text {
    display: inline-block;
    width: 1em;
    background: pm-theme.$pm-disabled--light; //hsla(0, 0%, 0%, 0.08)
  }
}
