@use '@material/layout-grid/mixins' as layout-grid;

/* stylelint-disable */

.mdc-landing {
  &__checkmarks {
    display: grid;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    grid-gap: 2rem;
    list-style-type: none;
    grid-auto-flow: column;
    font-size: 125%;

    @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
      grid-auto-flow: row;
    }
  }

  &__checkmark {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
  }

  &__footer {
    display: grid;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
    grid-gap: 1em;
    background: hsl(0, 0%, 100%);
    grid-auto-flow: column;

    @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
      grid-auto-flow: row;
      text-align: center;
    }

    &__end {
      display: grid;
      align-items: center;
      justify-content: flex-end;
      grid-gap: 1em;
      grid-auto-flow: column;

      @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
        grid-auto-flow: row;
      }
    }
  }
}

/* stylelint-enable */