@use '../base/variables' as pm-theme;
@use '@material/layout-grid/mixins' as layout-grid;
@use '@material/radio/mixins' as mdc-radio-mixins;
@use '@material/theme/mixins' as mdc-theme-mixins;

.pm-premium-modal {
  &__content {
    display: grid;
    justify-items: center;
    padding: 3em 3em 0 3em;

    @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
      padding: 0.5em;
    }
  }

  &__actions {
    justify-content: center;
    padding: 1em 3em 1em;

    @media screen and (max-width: #{layout-grid.breakpoint-max(phone)}) {
      padding: 0.5em;
    }
  }

  &__options {
    display: grid;
    width: 100%;
    gap: 0.5em;
  }

  &__radio {
    display: grid;
    gap: 0.5em;

    .mdc-list-item::before,
    .mdc-list-item::after {
      display: none;
    }

    .mdc-radio {
      @include mdc-radio-mixins.unchecked-stroke-color(disabled);
      @include mdc-radio-mixins.checked-stroke-color(primary);
      @include mdc-radio-mixins.ink-color(surface);

      /* stylelint-disable */
      .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
        border-width: 5px;
      }
      /* stylelint-enable */
    }

    &__item {
      align-items: center;
      height: auto;
      padding: 1em;
      border: 2px solid hsl(180, 5%, 77%);

      &--selected {
        @include mdc-theme-mixins.prop(border-color, primary);
      }
    }

    &__label {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
    }
  }

  &__tag {
    margin-right: 0.5em;
    padding: 0.1em 0.3em;
    background: #{pm-theme.$pm-primary--light};
    color: #{pm-theme.$pm-primary};
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
  }
}
