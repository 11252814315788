@use 'variables';

/* stylelint-disable */
html {
  height: 100%;
}

body {
  display: grid;
  background: variables.$pm-background;
  height: 100%;
}

#app {
  display: grid;
  height: 100%;
}

img {
  vertical-align: baseline;
}

a:hover {
  text-decoration: none;
}

a:focus,
button:focus {
  outline: none;
}

.mdc-linear-progress {
  height: 10px;
  border-radius: 5px;

  &__bar-inner {
    border-top-width: 10px;
  }

  &__buffering-dots {
    background-size: 10px 10px;
  }
}
/* stylelint-enable */
