@use '@material/theme/mixins' as theme-mixins;
@use '@material/typography/mixins' as typography-mixins;
@use '@material/animation/functions' as animation;
@use '@material/layout-grid/mixins' as layout-grid-mixins;
@use '../base/variables' as pm-theme;

.pm-question {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  flex: 1 1 0;
  align-content: flex-start;
  justify-content: center;
  padding: 1em;
  gap: 2.5em;
  text-align: center;
  overflow: auto;

  &__ask {
    @include typography-mixins.typography(headline2);

    font-weight: 400;
  }

  &__challenge {
    @include typography-mixins.typography(headline1);
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 25vh;
    margin-right: auto;
    margin-bottom: 1rem;
    margin-left: auto;

    @media screen and (min-width: #{layout-grid-mixins.breakpoint-min(desktop)}) {
      max-height: 40vh;
    }
  }

  &__challenge + &__image {
    margin-top: 2rem;
  }

  &__fake-cloze {
    display: inline-block;
    margin-right: 0.2em;
    margin-left: 0.2em;
    padding-right: 0.2em;
    padding-left: 0.2em;
    border-bottom: 2px solid pm-theme.$pm-disabled;

    &--show {
      margin-right: 0.1em;
      margin-left: 0.1em;
      border-color: transparent;
      background-color: pm-theme.$pm-primary--light;
      color: pm-theme.$pm-primary;
    }
  }

  &__answers {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: stretch;
    justify-content: center;
    margin-top: -1rem;
    margin-left: -1rem;
    padding-bottom: 1rem;
    justify-items: center;
  }

  &--column &__answers {
    flex-direction: column;
  }

  &__answer {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  &__translations {
    display: grid;
    align-content: flex-end;
    padding-bottom: 1rem;
    gap: 0.5em;
  }

  &__translation {
    display: flex;
    align-items: center;
  }

  &__translation-flag {
    margin-right: 0.5em;
  }
}

.pm-lesson-show {
  @include theme-mixins.prop(background-color, surface);

  display: flex;
  flex-direction: column;
  height: 100%;

  &__menu {
    display: grid;
    position: relative;
    flex: 1 1 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    justify-items: center;
    gap: 1em;
  }

  &__inner {
    display: flex;
    flex: 1 1 0;
    overflow: hidden;
  }

  &__header {
    display: grid;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    grid-template-columns: auto auto;
    gap: 0.5em;

    &__hearts {
      display: flex;
      align-items: center;
    }

    &__progress {
      @media screen and (max-width: #{layout-grid-mixins.breakpoint-max(tablet)}) {
        grid-column: span 2;
        order: 1;
      }
    }

    @media screen and (min-width: #{layout-grid-mixins.breakpoint-min(desktop)}) {
      padding: 1.25em 2em;
      grid-template-columns: auto 1fr auto;
      gap: 4em;
    }
  }

  &__footer {
    @include theme-mixins.prop(background-color, surface);
    @include theme-mixins.prop(color, on-surface);

    --mdc-theme-surface: #{pm-theme.$pm-disabled--light};
    --mdc-theme-on-surface: #{pm-theme.$pm-disabled--dark};

    flex: 0 0 76px;
    min-height: 76px;
    transition:
      animation.standard(color, 500ms),
      animation.standard(background-color, 500ms);
    overflow: auto;

    &__inner {
      display: grid;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      justify-items: stretch;
      height: 100%;
      padding: 0 1em;
      grid-auto-flow: column;
      overflow: auto;
    }

    &__status {
      @include typography-mixins.typography(headline3);

      display: flex;
      align-items: center;

      @media screen and (min-width: #{layout-grid-mixins.breakpoint-min(desktop)}) {
        @include typography-mixins.typography(headline2);
      }
    }

    /* stylelint-disable */
    &__status .material-icons {
      font-size: inherit;
      margin-right: .5em;
    }
    /* stylelint-enable */

    &--ok {
      --mdc-theme-surface: #{pm-theme.$pm-primary--light};
      --mdc-theme-on-surface: #{pm-theme.$pm-primary};
    }

    &--fail {
      --mdc-theme-surface: #{pm-theme.$pm-secondary--light};
      --mdc-theme-on-surface: #{pm-theme.$pm-secondary};
    }
  }
}
